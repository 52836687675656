import Button from "../button";
import data from "../../data";
import axios from "axios";
import { useState } from "react";

const BurgerMenu = ({ showActivePage }) => {
  const [logoutPopupShow, setLogoutPopupShow] = useState(false);
  const buttonSpan = <span className="button__span button__span_burger"></span>

  function logout() {
    const userId = localStorage.getItem("user");
    axios.post(data.regURL, { id: userId, logout: localStorage.getItem("token") });
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setLogoutPopupShow(false);
    showActivePage("author", "Вход");
  }

  function showDevices() {
    showActivePage("devices", "Устройства");
  }

  const logoutPopup = <div className="form popup__form form_flex">
    <div className="form__label">Вы уверены, что хотите выйти из аккаунта?</div>
    <div class="form form_flex" style={{marginTop: "13vh", gap: "20px"}}>
      <Button addClass="form__button button_light" buttonSpan="Да" type="submit" onClick={logout}/>
      <Button addClass="form__button button_trantparent" buttonSpan="Отмена" type="submit" onClick={()=>setLogoutPopupShow(false)}/>
    </div>
  </div>


  return (
    <div className="burger-menu">
      <input className="burger-menu__toggle" id="menu__toggle" type="checkbox" />
      <label className="burger-menu__button" htmlFor="menu__toggle">
        <Button addClass="button header__button button_burger" buttonSpan={buttonSpan} />
      </label>

      <ul className="burger-menu__box">
        <label className="burger-menu__exit" htmlFor="menu__toggle">
        </label>
        {logoutPopupShow ? <li>{logoutPopup}</li> : <>
          <li className="burger-menu__item" onClick={() => setLogoutPopupShow(true)}><label htmlFor="menu__toggle">Выход</label></li>
          <li className="burger-menu__item" onClick={showDevices}><label htmlFor="menu__toggle">Мои устройства</label></li>
          <li className="burger-menu__item"><a href={`https://biomatic24.ru/${localStorage.getItem("user")}`} target="_self">Настройки уведомлений</a></li></>}
      </ul>
    </div>
  )
}

export default BurgerMenu